import { ElMessage } from "element-plus";

/**错误信息提示 */
const remindErrorMessage = (message) => {
  if (!sessionStorage.getItem("sw_canRemindErrorMessage")) {
    sessionStorage.setItem("sw_canRemindErrorMessage", 1);
    ElMessage({
      message: message,
      type: "warning",
    });
    setTimeout(() => {
      sessionStorage.removeItem("sw_canRemindErrorMessage");
    }, 2000);
  }
};

export { remindErrorMessage };
