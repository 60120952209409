<template>
  <!-- 添加实体抽屉 -->
  <el-drawer
    custom-class="create-entity"
    direction="rtl"
    :with-header="false"
    size="740"
    v-model="state.showDrawer"
    :before-close="handleDrawerClose"
  >
    <!-- 标题 -->
    <div class="drawer-title">
      添加实体<el-icon @click="handleDrawerClose"><Close /></el-icon>
    </div>
    <!-- 标签页 -->
    <el-tabs
      class="add-entity-tab"
      v-model="state.activeName"
      @tab-click="handleClick"
    >
      <!-- 实体信息 -->
      <el-tab-pane label="实体信息" name="entity">
        <el-scrollbar>
          <el-form v-model="entityState" label-width="56px" class="entity-form">
            <el-form-item label="实体名称">
              <el-input
                v-model="entityState.entityName"
                placeholder="请输入内容"
                maxlength="100"
                clearable
              />
            </el-form-item>
            <el-form-item label="义项">
              <el-input
                v-model="entityState.entityMeaning"
                maxlength="100"
                clearable
              />
            </el-form-item>
            <el-form-item label="实体类型">
              <el-tag
                v-for="entityType in entityState.entityTypes"
                :key="entityType"
                class="mx-1"
                closable
                :disable-transitions="false"
                @close="handleEntityTypeClose(entityType)"
              >
                {{ entityType }}
              </el-tag>
              <!-- <el-input
              v-if="state.entityTypeInputVisible"
              ref="inputRef"
              v-model.trim="state.entityTypeinputValue"
              class="ml-1 w-20 type-input"
              size="small"
              @keyup.enter="(event) => event.target.blur()"
              @blur="handleEntityTypeInputConfirm"
            /> -->
              <el-cascader
                v-if="state.entityTypeInputVisible"
                v-model.trim="state.entityTypeinputValue"
                placeholder="请选择"
                class="ml-1 w-20 type-input"
                size="small"
                ref="inputRef"
                @blur="handleEntityTypeInputConfirm"
                :options="options"
                @change="handleChange"
                filterable
                clearable
              />
              <el-button
                v-else
                class="button-new-tag ml-1 type-btn"
                size="small"
                @click="showInput"
              >
                + 实体类型
              </el-button>
            </el-form-item>
            <el-form-item label="实体描述">
              <el-input
                v-model="entityState.entityDescription"
                type="textarea"
                resize="none"
                :rows="6"
                maxlength="1000"
              />
            </el-form-item>
            <h4>实体属性</h4>
            <el-row
              v-for="(attribute, attributeIndex) in entityState.entityAttribute"
              :key="attributeIndex"
            >
              <el-col :span="6">
                <el-form-item>
                  <el-input
                    type="text"
                    class="attribute-name"
                    placeholder="属性名"
                    maxlength="100"
                    v-model="attribute.name"
                    clearable
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item>
                  <el-input
                    type="text"
                    placeholder="属性值"
                    maxlength="100"
                    v-model="attribute.value"
                    clearable
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <el-icon
                  class="add-attribute"
                  @click="addAttribute(attributeIndex)"
                  ><CirclePlus
                /></el-icon>
                <el-icon
                  class="delete-entity-attribute"
                  v-if="entityState.entityAttribute.length > 1"
                  @click="deleteAttribute(attributeIndex)"
                  ><Delete
                /></el-icon>
              </el-col>
            </el-row> </el-form
        ></el-scrollbar>
      </el-tab-pane>
      <!-- 关系信息 -->
      <el-tab-pane label="关系信息" name="relation">
        <el-scrollbar>
          <el-form :model="relationState" class="relation-form">
            <!-- 实体名称 -->
            <el-row class="entity-name-got">{{
              entityState.entityName
            }}</el-row>
            <!-- 关系信息主体区域 -->
            <div
              class="relation-information"
              v-for="(item, key) in relationState.relationItem"
              :key="key"
            >
              <!-- 关系类型与相关实体 -->
              <el-row>
                <el-col :span="6">
                  <el-cascader
                    placeholder="选择关系类型"
                    popper-class="cascader-style"
                    clearable
                    filterable
                  />
                </el-col>
                <el-col :span="3">
                  <el-cascader placeholder="&#8594;" clearable />
                </el-col>
                <el-col :span="14">
                  <el-cascader
                    class="relation-entity-cascader"
                    placeholder="选择相关实体"
                    clearable
                    filterable
                    style="width: 100%"
                  />
                </el-col>
                <el-col :span="1">
                  <el-icon
                    class="delete-attribute"
                    v-if="relationState.relationItem.length > 1"
                    @click="deleteRelationItem(key)"
                    ><Delete
                  /></el-icon>
                </el-col>
              </el-row>
              <!-- 关系属性与属性值 -->
              <el-row v-for="(attr, attrkey) in item.attribute" :key="attrkey">
                <el-col :span="1">
                  <el-icon
                    class="add-attribute"
                    @click="addRelationAttribute(key, attrkey)"
                    ><CirclePlus
                  /></el-icon>
                </el-col>
                <el-col :span="8">
                  <el-input
                    placeholder="请输入两者关系的属性名"
                    maxlength="100"
                    clearable
                    v-model="attr.name"
                  />
                </el-col>
                <el-col :span="14">
                  <el-input
                    placeholder="请输入内容"
                    autosize
                    type="textarea"
                    clearable
                    resize="none"
                    maxlength="1000"
                    v-model="attr.value"
                  />
                </el-col>
                <el-col :span="1">
                  <el-icon
                    class="delete-attribute"
                    v-if="item.attribute.length > 1"
                    @click="deleteRelationAttribute(key, attrkey)"
                    ><Delete />
                  </el-icon>
                </el-col>
              </el-row>
            </div>
            <!-- 新增关系按钮 -->
            <p class="add-relation-btn" @click="addRelationItem()">
              <el-icon class="add-attribute"><CirclePlus /></el-icon>新增关系
            </p>
          </el-form>
        </el-scrollbar>
      </el-tab-pane>
      <!-- 参考来源 -->
      <el-tab-pane label="参考来源" name="reference">
        <el-scrollbar>
          <el-form
            :model="referenceState"
            label-width="56px"
            class="reference-form"
          >
            <div
              class="reference-information-item"
              v-for="(item, key) in referenceState.referenceOrigin"
              :key="key"
            >
              <el-form-item label="标题">
                <el-input maxlength="100" v-model="item.title" clearable />
              </el-form-item>
              <el-form-item label="链接">
                <el-input maxlength="100" v-model="item.link" clearable />
              </el-form-item>
              <el-form-item label="引用日期">
                <el-date-picker
                  type="date"
                  placeholder="请选择引用日期"
                  v-model="item.date"
                  clearable
                />
              </el-form-item>
              <p class="delete-reference-btn" @click="deleteReference(key)">
                删除
              </p>
            </div>
            <p class="add-reference-btn" @click="addReference()">
              <el-icon class="add-reference"><CirclePlus /></el-icon
              >新增参考来源
            </p>
          </el-form>
        </el-scrollbar>
      </el-tab-pane>
    </el-tabs>

    <!-- 底部按钮 -->
    <template #footer>
      <div style="flex: auto" class="entity-operation">
        <el-button size="large">保存</el-button>
        <el-button size="large" type="primary">提交</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script setup>
import { nextTick, reactive, ref, defineExpose } from "vue";
import { ElMessageBox } from "element-plus";
// const inputRef = ref(null);
const state = reactive({
  showDrawer: false,
  activeName: "entity",
  entityTypeInputVisible: false,
  entityTypeinputValue: "",
});
const handleClick = () => {};
const showInput = () => {
  state.entityTypeInputVisible = true;
  nextTick(() => {
    // inputRef.value.focus();
  });
};
const openDrawer = () => {
  state.showDrawer = true;
  // console.log("打开抽屉");
};

const entityState = reactive({
  entityName: "", //名称
  entityMeaning: "", //义项
  entityTypes: [
    "研究理论",
    "问题",
    "理论",
    "理论",
    "理论",
    "理论",
    "理论",
    "理论",
    "理论研究",
  ], //类型
  entityDescription: "", //描述
  entityAttribute: [{ name: "", value: "" }], //关系属性
}); //实体
const relationState = reactive({
  relationItem: [
    { type: "", entityRelated: "", attribute: [{ name: "", value: "" }] },
  ], //实体关系
}); //关系
const referenceState = reactive({
  referenceOrigin: [{ title: "", link: "", date: "" }], //参考来源信息
}); //参考

// 关闭抽屉前二次确认
const handleDrawerClose = () => {
  ElMessageBox.confirm("此操作将取消添加实体, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      state.showDrawer = false;
    })
    .catch(() => {
      state.showDrawer = true;
    });
};

//删除当前实体类型
const handleEntityTypeClose = (entityType) => {
  entityState.entityTypes.splice(
    entityState.entityTypes.indexOf(entityType),
    1
  );
};
//添加当前实体类型
const handleEntityTypeInputConfirm = () => {
  if (state.entityTypeinputValue) {
    entityState.entityTypes.push(state.entityTypeinputValue);
    state.entityTypeInputVisible = false;
    state.entityTypeinputValue = "";
  } else {
    state.entityTypeInputVisible = false;
  }
};
//添加实体属性
const addAttribute = (attributeIndex) => {
  entityState.entityAttribute.splice(attributeIndex + 1, 0, {});
};

// 删除实体属性
const deleteAttribute = (attributeIndex) => {
  entityState.entityAttribute.splice(attributeIndex, 1);
};

// 删除关系
const deleteRelationItem = (key) => {
  relationState.relationItem.splice(key, 1);
};

// 添加关系
const addRelationItem = () => {
  relationState.relationItem.push({
    type: "",
    entityRelated: "",
    attribute: [{ name: "", value: "" }],
  });
};

// 添加关系属性
const addRelationAttribute = (key, attrkey) => {
  relationState.relationItem[key].attribute.splice(attrkey + 1, 0, {});
};

// 删除关系属性
const deleteRelationAttribute = (key, attrkey) => {
  relationState.relationItem[key].attribute.splice(attrkey, 1);
};

// 添加参考来源
const addReference = () => {
  referenceState.referenceOrigin.push({});
};

// 删除参考来源
const deleteReference = (key) => {
  if (referenceState.referenceOrigin.length === 1) {
    referenceState.referenceOrigin[0].title = "";
    referenceState.referenceOrigin[0].link = "";
    referenceState.referenceOrigin[0].date = "";
  } else {
    referenceState.referenceOrigin.splice(key, 1);
  }
};

defineExpose({ openDrawer });
</script>

<style lang="scss" scoped>
.create-entity {
  // 标题
  .drawer-title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;

    .el-icon {
      float: right;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  // 标签页
  .add-entity-tab {
    // 三个表单的通用样式
    :deep(.el-tabs__item) {
      font-weight: 700;
      color: #888;
    }

    :deep(.is-active) {
      color: #101010;
    }

    .el-scrollbar {
      height: calc(100vh - 200px);

      .el-form {
        margin-right: 15px;
        :deep(.el-form-item__label) {
          padding: 0;
          margin-right: 16px;
          color: #101010;
        }

        :deep(.el-textarea__inner::placeholder) {
          font-family: "宋体";
        }

        .delete-attribute,
        .add-attribute,
        .add-reference,
        .delete-entity-attribute {
          font-size: 20px;
          margin-top: 5px;
          color: #4095e5;
          cursor: pointer;
        }

        .delete-entity-attribute {
          margin-left: 10px;
        }

        .relation-information,
        .reference-information-item {
          border-bottom: #bbbbbb 1px dashed;
          margin-top: 10px;
        }
      }
    }

    // 实体信息表单
    .entity-form {
      // 实体类型
      .type-input {
        width: 82px;
        height: 20px;

        :deep(.el-input__wrapper) {
          box-shadow: 0 0 0 0px
            var(--el-input-border-color, var(--el-border-color)) inset;
          border: 1px solid rgba(102, 102, 102, 1);
          border-radius: 10px;
        }
      }

      .type-btn {
        height: 20px;
        border-radius: 10px !important;
      }

      .el-tag {
        height: 20px;
        margin: 10px 10px 10px 0;
        border: none;
        border-radius: 10px;
        background-color: #d5d5d5;
        --el-tag-text-color: rgba(102, 102, 102, 1);
        --el-tag-hover-color: rgba(102, 102, 102, 1);
      }

      // 小标题
      h4 {
        margin: 23px 0 14px 0;
        text-align: left;
      }

      // 实体属性
      .el-row {
        .el-col {
          text-align: left;
          padding-left: 6px;
          .el-form-item {
            :deep(.el-form-item__content) {
              margin-left: 0 !important;
            }

            .attribute-name {
              margin-right: 10px;
            }
          }
        }
      }
    }

    // 关系信息表单
    .relation-form {
      .el-row {
        margin-bottom: 10px;
        .el-col {
          padding-right: 10px;
        }
      }

      // 实体名称
      .entity-name-got {
        color: #101010;
        font-size: 16px;
        line-height: 32px;
        text-align: left;
      }

      // 新增关系按钮
      .add-relation-btn {
        width: 90px;
        height: 25px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
        color: #3894ff;
        cursor: pointer;
        line-height: 25px;

        .add-attribute {
          margin-top: -2px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }

    // 参考来源表单
    .reference-form {
      :deep(.el-date-editor) {
        --el-date-editor-width: 100%;
      }

      // 主体内容
      .reference-information-item {
        margin-top: 20px;

        .delete-reference-btn {
          color: #ff1600;
          text-align: right;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }

      .add-reference-btn {
        margin: 10px 0 30px 0;
        color: #3894ff;
        cursor: pointer;
        width: 110px;
        height: 25px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
        line-height: 25px;

        .add-reference {
          margin-top: -3px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
  }
  // 底部按钮
  .entity-operation {
    text-align: center;

    .el-button {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
</style>
