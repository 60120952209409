/** axios封装的接口请求方法 */
import axios from "axios";
import VueCookies from "vue-cookies";
import { ElMessage } from "element-plus";
import { remindErrorMessage } from "@/utils/utils";
// import { getAttrNameOfOrgId, remindErrorMessage } from "@/tool/index";
import router from "@/router";
// import store from "@/store";

let qs = require("qs");

axios.defaults.withCredentials = true;

let baseUrl = `https://kg.ringdata.com/graphapi/`; //zgx
let baseAuthUrl = `http://175.102.15.229:8065/auth/`; //175

/**
 * post 请求
 */
const httpPost = function (url = "", data = {}, contentType, rType) {
  const instance = axios.create({
    baseURL: baseUrl,
    withCredentials: false,
    headers: {
      Accept: "application/json;charset=UTF-8",
      "Content-type": contentType ? contentType : "application/json",
    },
    responseType: rType,
  });
  return instance
    .post(url, data)
    .then((result) => {
      var res = result.data;
      if (res && res.code === 0) {
        return Promise.resolve(res);
      } else {
        if (res.code === 15000) {
          // remindErrorMessage("网络请求异常，请稍后再试或联系客服！");
          return res;
        } else if (res.code === 10000) {
          ElMessage({
            message: res.message,
            type: "warning",
          });
          return res;
        } else if (res.code === 10001) {
          if (res.data) {
            // remindErrorMessage("登陆信息已失效,请重新登陆");
            // let itemName = getAttrNameOfOrgId();
            // VueCookies.remove("tokenWeb");
            // VueCookies.remove("userInfo");
            // localStorage.removeItem(itemName); //去除orgid
            // localStorage.removeItem("sw_org_name"); //去除orgid
            // localStorage.removeItem("sw_is_admin"); //去除orgid
          } else {
            // remindErrorMessage("登陆信息已失效,请重新登陆");
          }
          setTimeout(() => {
            router.push("/login");
          }, 1000);

          return res;
        } else if (res.code === 10010) {
          // 无权限
          // router.push("/no-auth");
        }
        return Promise.resolve(res);
      }
    })
    .catch((error) => {
      let res = error.response;
      if (res.status === 400 || res.status === 401) {
        if (res.data) {
          // remindErrorMessage("登陆信息已失效,请重新登陆");
          // let itemName = getAttrNameOfOrgId();
          // VueCookies.remove("tokenWeb");
          // VueCookies.remove("userInfo");
          // localStorage.removeItem(itemName); //去除orgid
          // localStorage.removeItem("sw_org_name"); //
          // localStorage.removeItem("sw_is_admin"); //
        } else {
          // remindErrorMessage("登陆信息已失效,请重新登陆");
        }
        // setTimeout(() => {
        //   router.push("/login");
        // }, 1000);
        return res;
      }
      res = {
        code: -1,
        message: "网络请求异常，请稍后再试或联系客服！",
      };
      return res;
    });
};

// /**
//  * post 请求 uums
//  */
// const httpPostIsAnonymous = function (url = "", data = {}) {
//   const instance = axios.create({
//     baseURL: baseAuthUrl,
//     withCredentials: false,
//     headers: {
//       Accept: "application/json;charset=UTF-8",
//       "content-type": "application/json",
//     },
//   });
//   return instance
//     .post(url, JSON.stringify(data))
//     .then((result) => {
//       var res = result.data;
//       if (res && res.code === 0) {
//         return Promise.resolve(res);
//       } else {
//         if (res.code === 15000) {
//           ElMessage({
//             message: "网络请求异常，请稍后再试或联系客服！",
//             type: "warning",
//           });
//           return res;
//         }
//         return Promise.resolve(res);
//       }
//     })
//     .catch(() => {
//       let res = {
//         code: -1,
//         message: "网络请求异常，请稍后再试或联系客服！",
//       };
//       console.log("网络请求错误");
//       return res;
//     });
// };

// /**
//  * 认证请求
//  */
// const httpPostToken = function (url = "", data) {
//   const instance = axios.create({
//     baseURL: baseAuthUrl,
//     withCredentials: false,
//     headers: {
//       Authorization: "Basic bW9uZXR3YXJlOm1vbmV0d2FyZTIwMTkuQHNoLmNvbQ==",
//       "content-type": "application/x-www-form-urlencoded;charset=utf-8",
//     },
//   });
//   return instance
//     .post(url, data)
//     .then((result) => {
//       var res = result.data;
//       if (result && result.status === 200) {
//         if (res != null && res.access_token != null && res.access_token != "") {
//           localStorage.setItem("sw_visited_list", "[]");
//           VueCookies.set("tokenWeb", res.access_token, 24 * 60 * 60);
//           VueCookies.set(
//             "userInfo",
//             JSON.stringify(res.userInfo),
//             24 * 60 * 60
//           );
//           res = {
//             code: 0,
//             message: "登录成功",
//           };
//           return Promise.resolve(res);
//         } else {
//           res = {
//             code: -1,
//             message: "认证失败，请稍后再试或联系客服！",
//           };
//           return res;
//         }
//       }
//     })
//     .catch((error) => {
//       let res = error.response;
//       if (res.status === 400 || res.status === 401 || res.status === 500) {
//         res = {
//           code: res.data.code,
//           message: res.data.message,
//         };
//         ElMessage({
//           message: res.message,
//           type: "warning",
//         });
//       } else {
//         res = {
//           code: -1,
//           message: "网络请求异常，请稍后再试或联系客服！",
//         };
//         remindErrorMessage("网络请求异常，请稍后再试或联系客服！");
//       }

//       return res;
//     });
// };

// /**
//  * UUMS post 请求
//  */
// const httpAuthPost = function (url = "", data = {}) {
//   let tempUrl = baseAuthUrl;
//   const instance = axios.create({
//     baseURL: tempUrl,
//     withCredentials: false,
//     headers: {
//       Accept: "application/json;charset=UTF-8",
//       "content-type": "application/json",
//       Authorization: VueCookies.get("tokenWeb")
//         ? "Bearer " + VueCookies.get("tokenWeb")
//         : "",
//     },
//   });
//   return instance
//     .post(url, JSON.stringify(data))
//     .then((result) => {
//       var res = result.data;
//       if (res && res.code === 0) {
//         return Promise.resolve(res);
//       } else {
//         if (res.code === 15000) {
//           remindErrorMessage("网络请求异常，请稍后再试或联系客服！");
//           return res;
//         } else if (res.code === 10000) {
//           ElMessage({
//             message: res.message,
//             type: "warning",
//           });
//           return res;
//         }
//         return Promise.resolve(res);
//       }
//     })
//     .catch((error) => {
//       let res = error.response;
//       if (res.status === 400 || res.status === 401 || res.status === 500) {
//         store.commit("setLoginStatus", {
//           status: false,
//         });
//         if (res.data) {
//           ElMessage({
//             message: res.data.message,
//             type: "warning",
//           });
//           this.$cookies.remove("tokenWeb", "/", "smartsowo.com");
//           this.$cookies.remove("userInfo", "/", "smartsowo.com");
//         } else {
//           remindErrorMessage("登陆信息已失效,请重新登陆");
//         }
//         this.$router.push({
//           path: "/login",
//           query: {
//             fullPath: this.$route.fullPath,
//           },
//         });
//         return res;
//       }
//       res = {
//         code: -1,
//         message: "网络请求异常，请稍后再试或联系客服！",
//       };
//       console.log("网络请求错误");
//       return res;
//     });
// };

export {
  baseUrl,
  baseAuthUrl,
  httpPost,
  // httpPostIsAnonymous,
  // httpPostToken,
  // httpAuthPost,
};
